@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
    --yellow-color: #ffb400;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    overflow-x: hidden;
    background-color: #FEF3E2;
}

body.dark {
    background-color: #1e293b;
    color: #ddd;
}

#root {
    position: relative;
}

nav {
    height: 55px;
    width: 100%;
    color: #292929;
    display: flex;
    justify-content: space-between;
    align-items: center;
    overflow: hidden;
    background-color: transparent;
    position: absolute;
    top: 0;
    z-index: 100;
}

.nav-item {
    /* margin-right: 20px; */
    padding: 10px;
    color: #292929;
    font-weight: 300;
    transition: 0.3s;
    display: flex;
    gap: 5px;
    border-radius: 30px;
    align-items: center;
    /* background-color: transparent; */
}

.items {
    padding-right: 15px;
}

.items .nav-item:hover {
    background-color: var(--yellow-color);
}

.header {
    margin-left: 5%;
    font-weight: 800;

}

.header:hover {
    transform: translateY(-3px);
    scale: 1.1;
}

.home {
    position: relative;
}

.view-height {
    /* height: 100vh; */
    display: flex;
    position: relative;
    align-items: center;
    overflow: hidden;
    padding-top: 55px;
}

.home-block {
    position: absolute;
    height: 100%;
    width: 105%;
    transform: rotate(15deg);
    right: -70%;
    top: 0px;
    background: linear-gradient(135deg, var(--yellow-color) 30%, #ffdd57 100%);
    z-index: -1;
    pointer-events: none;
}

body.dark .home-block {
    background: linear-gradient(135deg, #f8ae00 30%, #e7c53e 100%);
}

.home-block-red {
    content: '';
    position: absolute;
    height: 500px;
    width: 500px;
    left: -200px;
    bottom: -500px;
    background-color: #FA4032;
}

body.dark .home-block::before {
    background-color: #ea372a;
}


.whoami {
    padding:2% 5% 5% 5%;
    height: 100%;
    display: flex;
    flex-direction: column;
}


.blog-btn {
    position: relative;
    width: 110px;
    height: 45px;
    border: #1e293b solid 3px;
}

.blog-btn-a:hover {
    transform: translate(-7px, -7px);
}


.blog-btn-a {
    transition: transform 0.3s ease-in-out;
    color: #292929;
    position: absolute;
    text-align: center;
    display: flex;
    justify-content: center;
    height: 45px;
    width: 110px;
    border: #1e293b solid 3px;
    bottom: -10px;
    right: -10px;
    background-color: #cbd5e1;
    z-index: 10;
}

body.dark .blog-btn {
    border: #ddd solid 3px;
}


body.dark .blog-btn-a {
    background-color: #1e293b;
    color: #ddd;
    border: #ddd solid 3px;
}

.explainer p:nth-child(n+2) {
    margin-top: 20px;
    font-size: 1.5rem;
    color: #292929;
    text-align: center;
    border-radius: 30px;
    font-weight: 400;
    position: relative;
}

.explainer-images {
    margin-top: 30px;
}

.explainer-images img {
    display: inline;
}


.bg {
    width: 30%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
    height: calc(100vh - 150px);
    z-index: 11;
    right: 40px;
    top: 80px;
    position: absolute;
    border: #fff solid 3px;
}


.teste {
    width: 285px;
    height: 285px;
    border: #fff solid 3px;
    border-radius: 50%;
    overflow: hidden;
}


.intro-section {
    width: 100%;
    height: fit-content;
}


section {
    overflow: hidden;
    text-align: center;
    color: #fdf4e4;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

section h1 {
    font-size: x-large;
    border-bottom: #fdf4e4 solid 2px;
    width: fit-content;
    height: fit-content;
    /* margin: 8px; */
    font-weight: 800;
    margin: 6px auto;
}

.middle-of-section  {
    /* display: flex;
    flex-direction: column;
    align-items: center; */
    text-align: center;
    width: clamp(100%, 540px, 540px);
    z-index: 100;
    height: 100%;
}

section p {
    /* width: clamp(100%, 5vw - 70%, 100%);     */
    padding: 18px 20px;
    font-size: large;
    font-weight: 500;
}


.test {
    display: flex;
    align-items: center;
    justify-content: space-evenly;

}

.ambulance {
    transform: rotate(20deg);
}


.filter-container {
    /* width: 60%; */
    margin: 0 auto;
    padding-top: 55px;
    position: relative;
}

.filter-container img, .filter-container svg {
    display: inline;
}


.list-items {
    width: 210px;
    height: 210px;
    background-color: #ffb400;
}


.visit {
    margin: 80px auto;
}

.hello {
    width: 100%;
    height: 300px;
}

.inner-type {
    border: #e5e7eb solid 3px;
    padding: 25px 30px;
    border-radius: 50px;
    color: rgb(234, 234, 234);
    background-color: #303d51;
    font-family: Fira Code VF, ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  }

  .item-container {
    overflow: auto;
  }

  body.dark .post {
    background-color: #293951;
  }
  .blog-cont {
    color: #131313;
    margin: 0 auto;
  }

  body.dark .blog-cont {
    background-color: #293951;
    color: #ddd;
  }
  
  @keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translateY(50px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .fade-in-up {
    animation: fadeInUp 1s ease-out forwards;
  }
